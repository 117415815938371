import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import ReactGA from "react-ga";
import { AppContext } from "./../../AppContext";
import Header from "./../../components/Header";

import HOMEIMG from "./../../assets/languages.jpg";
import { AppButton } from "./../../components/Button";

const ContainerWrapper = styled(Container)`
  margin-bottom: 30px;
`;
const Div = styled.div`
  margin-top: 130px;
  /* margin-bottom: 60px; */
  h1 {
    font-family: Armata-Regular;
    font-size: 22px;
    text-align: center;
  }
  & {
    @media screen and (max-width: 600px) {
      margin-top: 110px;
    }
  }
`;

const Languages = () => {
  let history = useHistory();
  const { languages, setLanguage } = useContext(AppContext);

  const selectLanguage = (item) => {
    setLanguage(item);
    runGA(item);
    history.push("/");
  };

  const runGA = (item) => {
    ReactGA.event({
      category: "Language",
      action: `Selected -${item.nombre}`,
      value: parseInt(item.id),
    });
  };

  return (
    <React.Fragment>
      <Header />
      <Div>
        <h1>Audioguía</h1>
      </Div>

      <ContainerWrapper>
        <Row>
          <Col>
            <img className="w-100" src={HOMEIMG} alt="" />
          </Col>
        </Row>
        {languages.length < 1 && (
          <Row>
            <Col className="d-flex justify-content-center">
              <ClockLoader size={100} color={"#D58A95"} loading={true} />
            </Col>
          </Row>
        )}
        {languages.length > 0 &&
          languages.map((item) => {
            return (
              <Row key={`lang-${item.id}`} className="mt-4">
                <Col className="d-flex justify-content-center">
                  <AppButton
                    onClick={() => {
                      selectLanguage(item);
                    }}
                  >
                    <span>{item.nombre}</span>&nbsp;
                  </AppButton>
                </Col>
              </Row>
            );
          })}
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default Languages;
