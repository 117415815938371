import React, { useContext } from "react";
import BurgerMenu from "react-burger-menu";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Accordion } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import styled from "styled-components";
import { AppContext } from "./../../AppContext";
import MenuWrap from "./../Menu";

import MenuIcon from "./../../assets/svg/menu.svg";
import CerrarIcon from "./../../assets/svg/cerrar.svg";
import ArrowOpen from "./../../assets/svg/flecha_al_lado.svg";
import ArrowClose from "./../../assets/svg/flecha_abajo.svg";
import LOGO from "./../../assets/logo_azul.png";
import ImageLogo from "./../../assets/logov1.jpg";

const Image = styled.img`
  padding-top: 75px;
  width: 115px;
  height: 205px;
`;

const MenuDiv = styled.div`
  background-image: url('${MenuIcon}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;
const CloseDiv = styled.div`
  background-image: url('${CerrarIcon}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;
const ImgA = styled.img`
  height: 10px;
  width: 10px;
  margin-left: 20px;
`;

const ImgLogo = styled.img`
  width: 150px;
  margin-top: 100px;
  margin-left: 50px;
`;

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a role="button" tabindex="0" onClick={decoratedOnClick}>
      <ImgA src={!isCurrentEventKey ? ArrowOpen : ArrowClose} alt="" />
      {children}
    </a>
  );
}

const Drawer = () => {
  let location = useLocation();
  const {
    collections,
    language,
    setFilter,
    questionnaire,
    doQuestionnaire,
  } = useContext(AppContext);

  const collapse = (element, index) => {
    const subcategorias = sorts(element.subcategorias);
    return (
      <Accordion style={{ marginTop: index === 0 ? 50 : 0 }}>
        <ContextAwareToggle eventKey="0">
          {" "}
          <span style={{ marginLeft: 10 }}>
            {element.nombre[language.abreviatura]}
          </span>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey="0">
          <React.Fragment>
            {subcategorias.map((item, i) => {
              return (
                <Link
                  onClick={(event) => {
                    selectSubMenu(event, item);
                  }}
                  key={`link-${element.id}-${i}`}
                  to={`/collection/${element.id}/subCollection/${item.id}`}
                >
                  <span style={{ marginLeft: 70 }}>
                    {item.nombre[language.abreviatura]}
                  </span>
                </Link>
              );
            })}
          </React.Fragment>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  const sorts = (array) => {
    for (let i = 0; i < array.length - 1; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (parseInt(array[i].numero) > parseInt(array[j].numero)) {
          let aux = array[i];
          array[i] = array[j];
          array[j] = aux;
        }
      }
    }
    return array;
  };

  const getItems = () => {
    let items = [];

    collections.forEach((element, i) => {
      if (element.nombre[language.abreviatura]) {
        if (
          element.subcategorias.length &&
          showCollection(element.subcategorias)
        ) {
          items.push(collapse(element, i));
        } else if (showCollection(element.obras)) {
          items.push(
            <Link
              style={{ marginTop: i === 0 ? 50 : 0 }}
              onClick={closeMenu}
              key={`link-${element.id}`}
              to={`/collection/${element.id}`}
            >
              <span>{element.nombre[language.abreviatura]}</span>
            </Link>
          );
        }
      }
    });

    return items;
  };

  const hideMenu = () => {
    return (
      location.pathname.includes("collection") &&
      location.pathname.includes("artwork")
    );
  };

  const closeMenu = (event) => {
    setFilter(null);
    const cb = document.querySelector(".bm-cross-button button");
    cb.click();
  };

  const selectSubMenu = (event, value) => {
    setFilter(value);
    const cb = document.querySelector(".bm-cross-button button");
    cb.click();
  };

  const getMenu = () => {
    const Menu = BurgerMenu["slide"];

    return (
      <MenuWrap wait={20} side="left">
        <Menu
          id="slide"
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
          right={false}
          customBurgerIcon={<MenuDiv />}
          customCrossIcon={<CloseDiv />}
          bodyClassName={"modal-open"}
        >
          <Row>
            <Col className="d-flex justify-content-center">
              <Image src={LOGO} alt="Logo App" />
            </Col>
          </Row>
          {getItems()}
          {doQuestionnaire && questionnaire.length > 0 && (
            <Link
              onClick={closeMenu}
              key={`link-questionnaire`}
              to={`/questionnaire`}
            >
              <span>{language.CUESTIONARIO}</span>
            </Link>
          )}
          <Row>
            <Col className="">
              <ImgLogo src={ImageLogo} alt="" />
            </Col>
          </Row>
        </Menu>
      </MenuWrap>
    );
  };

  const showCollection = (obras) => {
    let flag = false;
    for (let i = 0; i < obras.length; i++) {
      if (
        (obras[i].titulo && obras[i].titulo[language.abreviatura]) ||
        (obras[i].nombre && obras[i].nombre[language.abreviatura])
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  return (
    <React.Fragment>
      {!hideMenu() && language && collections.length > 0 && getMenu()}
    </React.Fragment>
  );
};

export default Drawer;
